import '../styles/shirt.css'
import Shirt from './Shirt'


const Shirts = () => {
    return (
        <div className='items_container'>
            {/* <div className="item_header">
                <div className="item_header_text">
                    <h1>Coming Soon</h1>
                </div>
            </div> */}
            <div className="items">
                <Shirt id={1} />
                <Shirt id={2} />
                <Shirt id={3} />
            </div>
        </div>
    );
}

export default Shirts;