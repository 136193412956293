// import logo from './logo.svg';
// import CommingSoon from './components/CommingSoon';
import Shirts from './components/Shirts';
import './styles/App.css';

function App() {
  return (
    <>
    <div className="App">
      <header className="App-header">
        <Shirts/>
      </header>
    </div>
    </>
  );
}

export default App;
