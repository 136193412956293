import '../styles/header.css';
import Socials from './Socials';

const Header = () => {

    const handleClick = () => {
        window.location.href = '/';
    }

    return (
        <div className="container">
            <div className='content'>
                <div className="header_name" onClick={handleClick}>
                    <img src='./full_white_ducky.png' alt="My Image" className='header_logo'/>
                    <h1>Duckiess</h1>
                </div>
                <div className='header_socials'>
                    <Socials />
                </div>
            </div>
            <div className='header_content_divider'>
                <div className='divider'>
                    
                </div>
            </div>
        </div>
    );
}

export default Header;