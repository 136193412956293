import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import '../styles/shirt.css'

const Shirt = (props) => {
    const [likes, setLikes] = useState(0);
    const [hasLiked, setHasLiked] = useState(false);
    const [title, setTitle] = useState('Duckie');
    const [img_url, setImgUrl] = useState();
    const [showHeart, setShowHeart] = useState(false);
    const [color, setColor] = useState('');

    const getItemLikes = async () => {
        try {
            const res = await fetch(`/api/getlikes?id=${props.id}`);
            const data = await res.json();
            setLikes(data.likes);
            setTitle(data.name);
            setImgUrl(data.img_url);
            setColor(data.color);
        } catch (error) {
            console.log(error);
        }
    }

    const giveLike = async () => {
        if (!hasLiked) {
            try {
                const res = await fetch('/api/givelike', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ id: props.id }),
                });
                const data = await res.json();
                console.log(data);
                setLikes(data.likes);
                setHasLiked(true); // Add this line
                let likedItems = Cookies.get('likedItems');
                if (!likedItems) {
                    Cookies.set('likedItems', String(props.id), { expires: 36500 }); // This sets the cookie to expire in 100 years
                } else {
                    likedItems = likedItems.split(',');
                    if (!likedItems.includes(String(props.id))) {
                        likedItems.push(String(props.id));
                        Cookies.set('likedItems', String(props.id), { expires: 36500 }); // This sets the cookie to expire in 100 years
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleDoubleClick = () => {
        if (!hasLiked) {
            giveLike();
            setShowHeart(true);
            setTimeout(() => setShowHeart(false), 1000); // hide after 1 second
        }
    };

    const removeLike = async () => {
        if (hasLiked) {
            try {
                const res = await fetch('/api/removelike', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ id: props.id }),
                });
                const data = await res.json();
                console.log(data);
                setLikes(data.likes);
                setHasLiked(false); // Add this line
                let likedItems = Cookies.get('likedItems');
                if (likedItems) {
                    likedItems = likedItems.split(',');
                    const index = likedItems.indexOf(String(props.id));
                    if (index > -1) {
                        likedItems.splice(index, 1);
                        Cookies.set('likedItems', likedItems.join(','));
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        const likedItems = Cookies.get('likedItems');
        if (likedItems && likedItems.split(',').includes(String(props.id))) {
            setHasLiked(true);
        } else {
            setHasLiked(false);
        }

        getItemLikes();
    }, []) // Empty dependency array means this effect runs once on component mount and not on updates

    return (
        <div className="item_container">
            <div>
                <div className='item_image_container'>
                    <div className="image-container">    
                        <img
                            src={img_url}
                            alt={title}
                            className="item_image"
                            onDoubleClick={handleDoubleClick}
                        />
                        {showHeart && <div className="heart-animation">❤️</div>}
                    </div>
                </div>
                <div className="item_info">
                    <div className="color-title">
                        <h1 className="item_title">{title}</h1>
                        <div className="color_circle" style={{ backgroundColor: color }}></div>
                    </div>
                    <div className="like_container">
                        <div className="like_heart" onClick={hasLiked ? removeLike : giveLike}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-heart" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ff2d53" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M6.979 3.074a6 6 0 0 1 4.988 1.425l.037 .033l.034 -.03a6 6 0 0 1 4.733 -1.44l.246 .036a6 6 0 0 1 3.364 10.008l-.18 .185l-.048 .041l-7.45 7.379a1 1 0 0 1 -1.313 .082l-.094 -.082l-7.493 -7.422a6 6 0 0 1 3.176 -10.215z" fill={hasLiked == true ? 'currentColor' : 'none' } />
                            </svg>
                        </div>
                        <p>{likes} {likes !== 1 ? "likes" : "like"}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Shirt;