// Banner.js
import React from 'react';
import '../styles/banner.css';

const Banner = ({ openModal }) => {
    return (
        <div className="banner" onClick={openModal}>
            <p>Products coming soon! ❤️ vote on merch below.</p>
        </div>
    );
}

export default Banner;