import '../styles/footer.css';
import JoinMailingList from './JoinMailingList';
import Socials from './Socials';

const Footer = () => {

    const handleClick = () => {
        window.location.href = '/';
    }

    return (
        <div className="footer_container">
            <div className='footer_divider_container'>
                <div className='footer_divider'/>
            </div>
            <div className='footer_content'>
                <div className="footer_name" onClick={handleClick}>
                    <img
                        src="./fully_black_ducky.png"
                        alt="Logo"
                        className="footer_logo"
                    />
                    <h1>Duckiess</h1>
                </div>
                {/* <p className='join_mail_text'>Join Mailing List</p> */}
                <JoinMailingList />
                <div className="footer_socials">
                    <Socials />
                </div>
            </div> 
        </div>
    );
}

export default Footer;