import React from 'react';
import '../styles/socials.css';

const Socials = () => {
    // icon sizes
    const iconSize = 32;

    // social links
    const socialLinks = {
        youtube: 'https://www.youtube.com/channel/UC-HNPPL8YkHofBB9jyoOREA',
        instagram: 'https://www.instagram.com/duckiessstore',
        tiktok: 'https://www.tiktok.com/@duckiessstore'
    }

    // handle social click
    const handleSocialClick = social => {
        window.open(social, '_blank');
    }

    return (
        <>
            <div className="header_social" onClick={() => { handleSocialClick(socialLinks.tiktok) }}>
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" fill="currentColor" class="bi bi-tiktok" viewBox="0 0 16 16">
                    <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
                </svg> */}
                <img
                    width={iconSize} height={iconSize}
                    src="https://img.icons8.com/color/48/tiktok--v1.png"
                    alt="Duckiess TikTok"
                />
            </div>
            <div className="header_social" onClick={() => { handleSocialClick(socialLinks.instagram) }}>
                {/* <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-instagram" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" />
                    <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                    <path d="M16.5 7.5l0 .01" />
                </svg> */}
                <img
                    width={iconSize} height={iconSize}
                    src="https://img.icons8.com/color/48/instagram-new--v1.png"
                    alt="Duckiess Instagram"
                />
            </div>
            <div className="header_social" onClick={() => { handleSocialClick(socialLinks.youtube) }}>
                {/* <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-youtube-filled" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M18 3a5 5 0 0 1 5 5v8a5 5 0 0 1 -5 5h-12a5 5 0 0 1 -5 -5v-8a5 5 0 0 1 5 -5zm-9 6v6a1 1 0 0 0 1.514 .857l5 -3a1 1 0 0 0 0 -1.714l-5 -3a1 1 0 0 0 -1.514 .857z" stroke-width="0" fill="currentColor" />
                </svg> */}
                <img
                    width={iconSize} height={iconSize}
                    src="https://img.icons8.com/color/48/youtube-play.png"
                    alt="Duckiess YouTube"
                />
            </div>
        </>
    );
}

export default Socials;