import React, { useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import '../styles/joinMailingList.css';

Modal.setAppElement('#root') // replace '#root' with the id of your app element

function JoinMailingList() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [errors, setErrors] = useState({email: '', name: ''});
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }
  
  const handleNameChange = (event) => {
    setName(event.target.value);
  }

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};

    if(!email.includes('@')) {
      formIsValid = false;
      errors["email"] = "*Please enter valid email.";
    }

    if(!name) {
      formIsValid = false;
      errors["name"] = "*Please enter your name.";
    }

    setErrors(errors);
    return formIsValid;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage(''); // Clear any old error message
    setSuccessMessage(''); // Clear any old success message
    if(validateForm()){
      try {
        const res = await axios.post('http://localhost:8633/joinmailinglist', {
          email: email,
          name: name
        });
        console.log(res.data);
        setSuccessMessage(res.data.message); // Set the success message
        // Don't close the modal here so the user can see the success message
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setErrorMessage('Email already subscribed');
        } else {
          console.log(error);
        }
      }
    }
  }

  return (
    <div>
      <button
        onClick={openModal}
        id="mailingListButton"
      >
        Join our mailing list
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Join Mailing List Modal"
        className="panel"
        overlayClassName="ReactModal__Overlay"
      >
        <div className='mail_content'>
          <h2>Join our mailing list</h2>
          <form onSubmit={handleSubmit}>
            <div className='discription'>
              <p>Sign up with your email address to receive news and updates</p>
            </div>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {successMessage && <div className="success-message">{successMessage}</div>}
            <input type="email" value={email} onChange={handleEmailChange} placeholder='Email' required />
            <input type="text" value={name} onChange={handleNameChange} placeholder='Name' required />
            <div className='button_container'>
              <button onClick={closeModal}>close</button>
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default JoinMailingList;